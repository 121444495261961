import { useGetUser } from "src/data/user/hooks/useGetUser"
import { formatDate } from "src/utils/l10n"

/** Wrapper component that displays time according to user prefererences */
export const LocalizedDate = ({
  date,
  className = "",
  timezone,
}: {
  date: string
  className?: string
  timezone?: string
}) => {
  const user = useGetUser()
  const clockType = user.clock_type
  let formattedDate
  try {
    formattedDate = String(formatDate({ date, clockType, timezone }))
  } catch (err) {
    formattedDate = String(date)
  }
  return <span className={className}>{formattedDate}</span>
}
